@use "mixins";
@use "global/bases";

.form-field {
	position: relative;
	width: 100%;

	&__field,
	&__label {
		display: block;
		line-height: 1.3;
		background: none;
	}

	&__field {
		color: var(--c-turquiose);
		border: 0 none;
		padding: 0.5em;
		width: 100%;
		appearance: none;
		background: rgba(#0099cc, 0.1);

		&:focus {
			outline: 1px solid var(--c-turquiose);
		}
	}

	&__boolean-group {
		> * + * {
			margin-top: calc(var(--p-rhythm-sm) / 2);
		}
	}

	&__label {
		margin-bottom: 0.5em;
		color: var(--c-turquiose);
		&-required {
			color: red;
		}
	}

	&__error-messages {
		color: red;
		width: 100%;
		&-single {
			margin-top: 10px;
		}
	}

	&__help-text {
		font-size: var(--fs-body-sm);
		margin-top: 1em;
		&--boolean {
			margin-top: 0;
			margin-bottom: 1em;
		}
	}

	&__wrapper {
		position: relative;
	}

	&--select {
		& svg {
			position: absolute;
			right: 15px;
			top: 50%;
			width: 8px;
			transform: translateY(-50%) rotate(90deg);
		}
	}

	&--clearablefileinput {
		& .form-field {
			&__field {
				&::file-selector-button {
					@include bases.button;
					text-transform: uppercase;
					font-size: var(--fs-body-sm);
					background-color: transparent;
					color: white;
					margin-right: 1em;
				}
			}
		}
	}

	&--timeinput,
	&--dateinput {
		& .form-field {
			&__field {
				&::-webkit-calendar-picker-indicator {
					filter: invert(1);
				}
			}
		}
	}
}
