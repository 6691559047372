.pagination {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: var(--p-rhythm-lg);

	&__numbers {
		color: var(--t-accent-color);
		display: flex;
		gap: 1em;
	}

	&__link {
		padding: 4px 9px 4px 9px;
		&--current {
			color: white;
			border: 1px solid var(--c-turquiose);
		}
	}

	&__next {
		position: relative;
		width: auto;
		display: inline-block;
		padding-right: 17px;
		& > svg {
			transition: transform 150ms ease-in-out;
			position: absolute;
			color: var(--c-turquiose);
			width: 6px;
			right: 0;
			top: 5px;
		}

		&:hover {
			& > svg {
				transform: translateX(2px);
			}
		}
	}
}
