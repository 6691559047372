@use "mixins";
@use "global/bases";

.accordion {
	&--item {
		$i: &;

		&:not(:first-child) {
			border-top: 2px solidvar(--t-accent-color);
			border-top: 1px solid rgba(#0099cc, 0.23);
		}

		&.is-active {
			#{$i}-trigger {
				svg {
					transform: rotate(-90deg);
				}
			}
		}

		&-trigger {
			padding: var(--p-rhythm-sm) 0;
			color: var(--c-turquiose);
			display: block;
			appearance: none;
			outline: none !important;
			position: relative;
			padding-right: 80px;

			&-icon {
				position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
				right: 0;
				top: 50%;
				width: 30px;
				height: 30px;
				transform: translateY(-50%);
				transition: background-color 150ms ease-in-out;
				svg {
					transform: rotate(90deg);
					color: var(--white);
					width: 10px;
					height: auto;
					position: absolute;
					transition: all 150ms ease-in-out;
				}

				&:hover {
					svg {
						color: var(--orange);
					}
				}
			}

			&:hover {
				cursor: pointer;
			}
		}
		&-content {
			overflow: hidden;
			transition: height 250ms ease-out;
			height: var(--accordion-height);
			padding-bottom: var(--p-rhythm-sm);

			&.is-closed {
				height: 0;
				padding: 0;
			}
		}
	}
}
