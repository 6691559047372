@use "mixins";
@use "global/bases";

.l-site-footer {
	max-width: var(--page-width);
	margin: 105px auto 0 auto;

	&__inner {
		padding-top: var(--p-rhythm-sm);
		border-top: 1px solid white;
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: var(--p-rhythm-sm);
	}

	&__copyright,
	&__copyright span {
		font-size: 13px;
	}

	&__copyright {
		margin-top: 2em;
		margin-bottom: 30px;
	}

	&__acknowledgement {
		margin-top: 105px;
		font-size: 15px;
		& > i {
			font-size: 15px;
		}
	}

	&__submenu {
		&-item {
			&:first-child {
				color: var(--c-turquiose);
			}

			& > a {
				@include bases.base-link;
				text-underline-offset: 0.2rem;
				line-height: 1.4;
			}

			& + & {
				margin-top: 0.3em;
			}
		}

		&--turq {
			color: var(--c-turquiose);
		}
	}

	@include mixins.from-md {
		&__inner {
			grid-auto-flow: column;
			grid-auto-columns: 1fr;
		}
	}
}
