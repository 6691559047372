@use "mixins";

.image-list {
	display: grid;
	row-gap: var(--p-rhythm-lg);

	&__item {
		display: flex;
		flex-direction: column-reverse;
		gap: var(--p-rhythm);
		&-text {
			& > h3 {
				font-size: var(--fs-title);
				margin-bottom: 0.5em;
			}
		}
	}

	@include mixins.from-md {
		row-gap: var(--p-rhythm);
		&__item {
			grid-template-columns: 1fr 1fr;
			display: grid;
			align-items: center;
		}
	}
}
