@use "mixins";

.arrowed-link {
	display: flex;
	// grid-template-columns: minmax(auto, 16ch) 50px;
	gap: var(--p-rhythm);
	align-items: center;

	& > span {
		text-transform: uppercase;
		font-size: var(--fs-body-sm);
		letter-spacing: 0.08em;
		line-height: 1.8;
	}

	& > svg {
		width: 50px;
		color: var(--c-turquiose);
		transition: color 250ms ease-in-out;
	}

	&:hover,
	&:active {
		& > svg {
			color: var(--c-orange);
		}
	}

	@include mixins.from-md {
		gap: var(--p-rhythm-sm);
	}
}
