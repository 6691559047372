.l-form {
	&__intro {
		margin-bottom: 1em;
		& + .l-form--spaced {
			margin-top: calc(var(--p-rhythm-sm) * 1.5);
		}
	}

	&__heading {
		margin: var(--p-rhythm-sm) 0;
		font-size: var(--fs-subtitle);

		&:first-child {
			margin-top: 0;
		}
	}
	.form-field + .form-field,
	.form-field + .btn,
	.form-field + div + .btn {
		margin-top: var(--p-rhythm-sm);
	}

	button[type="submit"] {
		width: 100%;
		text-align: center;
	}

	&--spaced {
		.form-field + .form-field {
			margin-top: calc(var(--p-rhythm-sm) * 1.5);
		}
	}
}
