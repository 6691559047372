@use "mixins";
@use "global/bases";

.home-section {
	padding: var(--page-section-padding) var(--page-gutter);
	margin: 0 auto;
	margin-top: calc(-1 * var(--page-section-padding));
	z-index: 2;
	position: relative;
	&__title {
		@include bases.title;
		margin-bottom: var(--p-rhythm-sm);
	}

	&--blue-bg {
		background: linear-gradient(
			to bottom,
			rgba(#030b13, 0.01),
			rgba(#030b13, 1) 15%,
			rgba(#030b13, 1) 85%,
			rgba(#030b13, 0.01)
		);
	}

	@include mixins.from-md {
		&__inner {
			max-width: calc(100% - (var(--p-rhythm-lg) * 2));
			margin-left: auto;
			margin-right: auto;

			&--skinny {
				padding: 0 var(--p-rhythm);
			}
		}

		&--blue {
			background: linear-gradient(
				to bottom,
				rgba(#030b13, 0.01),
				rgba(#030b13, 1) 28%,
				rgba(#030b13, 1) 73%,
				rgba(#030b13, 0.01)
			);
		}
	}
}
