@use "mixins";

.icon-grid {
	display: grid;
	grid-template-rows: auto auto;
	gap: calc(var(--p-rhythm) * 1.2); // ~100px

	& * {
		font-size: var(--fs-body);
	}

	&--minimal {
		grid-gap: var(--p-rhythm);
	}

	&__top {
		& > h3 {
			font-size: var(--fs-title);
		}
	}
	&__text {
		& > a {
			margin-top: var(--p-rhythm-sm);
		}
	}

	&__item {
		&--minimal {
			width: 175px;
		}
	}

	&__items {
		display: grid;
		grid-gap: var(--p-rhythm-lg);
		&--minimal {
			grid-template-columns: repeat(
				auto-fit,
				minmax(175px, 1fr)
			); //fit as many cols as possible before wrapping
		}
	}

	@include mixins.from-md {
		&__top {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-gap: var(--p-rhythm-sm);

			&--vert {
				grid-template-columns: 1fr;
				grid-gap: 0;
			}
		}
		&__text {
			grid-column: span 2;
		}
		&__item {
			&--minimal {
				min-width: 175px;
			}
		}
		&__items {
			grid-template-columns: 1fr 1fr 1fr;
			grid-gap: var(--p-rhythm);
			&--minimal {
				grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
				grid-gap: 25px;
			}
			&--minimal-4-col {
				grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
			}
		}
		&--minimal {
			grid-gap: var(--p-rhythm);
		}
	}
}
