@use "global/bases";
@use "mixins";

.blockquote {
	margin: 0;
	&__body {
		@include bases.base-heading;
		font-weight: 700;
		line-height: 1.5;
		font-size: var(--fs-body);
	}
	&__citation {
		font-style: italic;
	}

	@include mixins.from-md {
		&__body {
			font-size: 30px;
		}
	}
}
