@use "mixins";

.icon-list {
	&__left {
		& h3 {
			font-weight: 700;
			font-size: var(--fs-title);
			margin-bottom: 1em;
		}
	}

	&__subtitle {
		display: block;
		color: var(--c-turquiose);
	}
	&__items {
		display: grid;
		grid-auto-rows: auto;
		row-gap: var(--p-rhythm-lg);
	}

	&__intro {
		margin-bottom: var(--p-rhythm);
	}

	@include mixins.to-md {
		&__description,
		&__subtitle {
			display: none;
		}

		&__right {
			margin-top: var(--p-rhythm);
		}
	}
	@include mixins.from-md {
		display: grid;
		grid-template-columns: 1fr 2fr;
		gap: var(--p-rhythm-lg) var(--p-rhythm);
		&__sticky {
			position: sticky;
			top: var(--p-rhythm);
			grid-row: 2;
		}

		&__items {
			row-gap: var(--p-rhythm);
		}

		&--dual {
			.icon-list {
				&__left {
					display: grid;
					grid-template-rows: 100px auto; //.icon-item
					align-items: flex-start;
				}
			}
		}
	}
}
