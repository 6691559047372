@use "constants";
@use "mixins";

@function strip-units($number) {
	// Strips units from string e.g. 15px -> 15
	@return $number / ($number * 0 + 1);
}

@mixin gen-clamp(
	$propName,
	$minFontSizePx,
	$maxFontSizePx,
	$minWidthPx: constants.$bp-tiny,
	$maxWidthPx: constants.$bp-very-large
) {
	$minWidth: strip-units($minWidthPx) / 16;
	$maxWidth: strip-units($maxWidthPx) / 16;
	$minFontSize: strip-units($minFontSizePx) / 16;
	$maxFontSize: strip-units($maxFontSizePx) / 16;

	$slope: ($maxFontSize - $minFontSize) / ($maxWidth - $minWidth);
	$yAxisIntersection: -$minWidth * $slope + $minFontSize;

	#{$propName}: clamp(
		#{$minFontSize * 1rem},
		#{($yAxisIntersection * 1rem)} + " + " + #{($slope * 100vw)},
		#{$maxFontSize * 1rem}
	);

	// If these varaible names change, update them in updateClampedCSS.js
	@at-root {
		:export {
			#{$propName}_min-width: #{strip-units($minWidthPx)};
			#{$propName}_max-width: #{strip-units($maxWidthPx)};
			#{$propName}_min-fs: #{strip-units($minFontSizePx)};
			#{$propName}_max-fs: #{strip-units($maxFontSizePx)};
		}
	}
}

:root {
	--c-blue-dark: #030b13;
	--c-orange: #ff9900;
	--c-turquiose: #0099cc;

	--t-text-color: #ffffff;
	--t-accent-color: var(--c-turquiose);
	--t-accent-hover-color: var(--c-orange);
	--t-page-bg-color: var(--c-blue-dark);

	--header-height: 137px;

	--p-rhythm: 32px;
	--p-rhythm-sm: calc(var(--p-rhythm) / 2);
	--p-rhythm-lg: calc(var(--p-rhythm) * 2);
	--page-width: min(calc(100vw - var(--p-rhythm) * 2), 1300px);
	--page-gutter: calc((100vw - var(--page-width)) / 2);
	$content-responsive: calc(var(--page-width) - (2 * var(--page-gutter)));
	--content-width: 600px;
	--landing-width: 980px;
	--listing-width: 830px;
	--page-section-padding: 160px;

	--fs-body-sm: 16px;
	@include gen-clamp("--fs-body", 16px, 20px);
	@include gen-clamp("--fs-body-lg", 18px, 24px);
	@include gen-clamp("--fs-body-landing", 28px, 36px);
	@include gen-clamp("--fs-big-title", 30px, 70px);
	@include gen-clamp("--fs-title", 30px, 50px);
	@include gen-clamp("--fs-subtitle", 20px, 40px);

	@include mixins.to(980px) {
		--landing-width: 200px;
	}

	@include mixins.to(1200px) {
		--listing-width: 500px;
	}

	@include mixins.from-md {
		--p-rhythm: 80px;
		--page-section-padding: 330px;
	}
}

// fs-h1 = 70px
// fs-h2 = 50px
