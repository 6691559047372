@use "mixins";

.l-prose-blocks {
	> * + * {
		margin-top: var(--p-rhythm);
	}

	&__intro {
		grid-area: intro;
		margin-bottom: var(--page-section-padding);
		max-width: var(--landing-width);
		margin-left: auto;
		margin-right: auto;
		& > p {
			font-size: var(--fs-body-landing);
		}
		&--nav {
			max-width: 100%;
			display: grid;
			grid-template-areas: "aside .";
			column-gap: var(--p-rhythm-lg);
			grid-template-columns: auto auto;
		}

		@include mixins.to-md {
			max-width: none;
		}
	}

	&__date {
		color: var(--c-turquiose);
		font-weight: bold;
	}
	&--landing {
		> * + * {
			margin-top: var(--page-section-padding);
		}
	}
}
