@use "constants";
@use "mixins";
@use "global/bases";

.l-home-fold {
	grid-area: fold;
	padding: var(--header-height) var(--page-gutter) var(--p-rhythm-lg)
		var(--page-gutter);
	background-image: url(../images/home-fold-background.jpg);
	min-height: 90vh;
	background-size: cover;
	z-index: 1;

	& .btn {
		// To stop the "blue" from overlapping
		z-index: 10;
		position: relative;
	}
	&__tagline {
		@include bases.base-heading;
		font-size: var(--fs-big-title);
		line-height: 1.2;
		margin-bottom: var(--p-rhythm);
	}

	@include mixins.to-sm {
		padding-top: 40%;
		background-image: url(../images/home-fold-mobile-background.jpg);
	}

	@include mixins.from-md {
		min-height: 1100px;
		padding-top: var(--header-height); // bang on
		aspect-ratio: 96 / 55;
		&__tagline {
			margin-top: calc(var(--p-rhythm-lg) * 1.5);
			max-width: 18ch;
		}
	}
}
