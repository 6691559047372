@use "constants";
@use "mixins";
@use "global/bases";

@include mixins.to-md {
	.submenu {
		.orientation-changing & {
			transition: none;
		}

		&__image {
			display: none;
		}
		&__links {
			font-size: calc(var(--fs-body) * 1.5);
			li {
				margin-top: 0.6em;
			}
		}

		&__column {
			margin-top: var(--p-rythm);
		}
	}
}

@include mixins.from-md {
	.submenu {
		padding-top: var(--p-rhythm-sm);
		position: relative;
		left: 50%;
		transform: translateX(-50%) translateY(4px);
		&:before {
			$arrow-size: 18px;
			position: absolute;
			top: 22px; // NFI how this works
			left: 50%;
			transform: translateX(-50%);
			content: "";
			width: 0;
			height: 0;
			border-left: $arrow-size solid transparent;
			border-right: $arrow-size solid transparent;
			border-bottom: $arrow-size solid white;
		}
		&__links {
			padding: var(--p-rhythm-sm);
			background: white;

			&-single {
				color: #0c3245; // TODO not used anywhere else??
				white-space: nowrap;
			}
			&-single + &-single {
				margin-top: calc(var(--p-rhythm-sm) / 2);
			}
			a {
				@include bases.base-link;
				font-size: 18px;
			}
		}

		&:after {
			// invisible hover area to stop menu closing
			top: -10px;
			z-index: 1;
			left: 0;
			position: absolute;
			width: 100%;
			content: "";
			height: 50px;
		}

		&__back {
			display: none;
		}
	}
}
