@use "mixins";
@use "global/bases";

.category-card {
	margin-bottom: calc(var(--p-rhythm) * 1.5);

	&__title {
		@include bases.title;
		font-size: var(--fs-subtitle);
		margin-bottom: calc(var(--p-rhythm-sm) * 0.75);
	}

	&__description {
		margin: var(--p-rhythm-sm) 0;
	}
}

@include mixins.from-md {
	.category-card {
		&__title {
			font-size: var(--fs-title);
		}
	}
}
