@use "constants";
@use "mixins";

.l-site-header {
	position: relative;
	z-index: 100;
	&--inner {
		padding: var(--p-rhythm-sm) 0;
		border-bottom: 1px solid white;
		display: flex;
		justify-content: space-between;
	}
	&__logo {
		display: block;
		height: 32px;
		width: auto;
		margin-right: 100px;
		img {
			width: auto;
			height: 100%;
		}
	}

	&__menu-trigger {
		z-index: 3;
	}

	.l-navigation {
		z-index: 2;
	}

	.home:not(.browser-is-outdated) & {
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
	}
}

@include mixins.to-md {
	.l-site-header {
		padding: 0 var(--p-rhythm);
		&--inner {
			align-items: flex-end;
		}
	}
}

@include mixins.from-md {
	.l-site-header {
		margin-top: 0;
		&--inner {
			padding: 50px 0 35px 0;
			max-width: var(--page-width);
			margin: 0 auto;
		}

		&__logo {
			width: 250px;
			height: auto;
			margin-right: 20px;
			img {
				width: 100%;
				height: auto;
			}
		}

		&__menu-trigger {
			display: none;
		}
	}
}
