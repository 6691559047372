@use "mixins";
@use "global/bases";

.logo-flop {
	margin-top: var(--p-rhythm-sm);
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	align-items: center;
	row-gap: var(--p-rhythm);
	column-gap: 75px;
	justify-content: space-around;
	a {
		display: inline;
		width: auto;
	}
	img {
		height: auto;
		width: auto;
	}
}

@include mixins.to-sm {
	.logo-flop {
		a {
			max-width: calc(100% / 4);
			max-height: 40px;
		}
		img {
			width: 100%;
			max-height: 40px;
			height: auto;
		}
	}
}

@include mixins.to-md {
	.logo-flop {
		column-gap: var(--p-rhythm);
	}
}

@include mixins.from-md {
	.logo-flop {
		margin-top: 110px; // LM
	}
}
