.captioned-media {
	margin: 0;
	&__asset {
		width: calc(100% + 100px);
		transform: translateX(-50px);
		height: auto;
		display: block;
	}
	&__caption {
		margin-top: 1em;
		& * {
			font-size: 15px !important;
		}
	}
}
