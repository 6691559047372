$control-size: 1.5em;

.form-boolean {
	input {
		display: none;
	}
	&__label {
		position: relative;
		padding-left: calc(#{$control-size} + 15px);
		&:before,
		&:after {
			content: "";
			width: $control-size;
			height: $control-size;
			position: absolute;
			left: 0;
			top: 50%;
			margin-top: -#{$control-size/2};
		}
		&:before {
			border: 1px solid var(--c-turquiose);
		}
	}

	input[type="checkbox"] + &__label {
		&:after {
			display: block;
			left: 1px;
			top: 16px;
			transform: scale(0.9);
		}
		&:before {
			transition: background-color 200ms ease-in-out;
		}
		&:hover {
			&:before {
				background-color: rgba(white, 0.5);
			}
		}
	}

	input[type="checkbox"]:checked + &__label {
		&:after {
			content: url("../svgs/tick.svg");
		}
	}
	input[type="radio"] + &__label {
		&:after {
			transition: transform 200ms ease-in-out;
			transform: scale(0);
			background-color: white;
		}
		&:hover {
			&:after {
				transform: scale(0.3);
			}
		}
		&:before,
		&:after {
			border-radius: 100%;
		}
	}
	input[type="radio"]:checked + &__label {
		&:after {
			transform: scale(0.6);
		}
	}
}

.form-boolean-group {
	&__label {
		display: block;
		margin-bottom: 12px;
	}
	.form-boolean {
		+ .form-boolean {
			margin-top: var(--p-rhythm-sm);
		}
	}
}
