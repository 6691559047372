@use "mixins";

.featured-case-study {
	display: grid;

	&__text {
		display: flex;
		flex-direction: column;
		justify-content: center;

		& > h3 {
			font-size: var(--fs-title);
			margin-bottom: 0.8em;
		}

		& > a {
			margin-top: 1em;

			&:first-of-type {
				margin-top: var(--p-rhythm);
			}
		}
	}

	@include mixins.to-md {
		gap: var(--p-rhythm);

		&--diagram {
			& .featured-case-study {
				&__image {
					max-width: 420px;
					margin: 0 auto;
				}
			}
		}
	}

	@include mixins.from-md {
		padding: 0 var(--p-rhythm);
		grid-template-columns: 1fr 1fr;

		&__text {
			padding-left: calc(var(--p-rhythm-sm) * 1.5); // LM

			& > a {
				&:first-of-type {
					margin-top: calc(var(--p-rhythm) * 1.5);
				}
			}
		}
	}
}
