@use "mixins";

.staff-listing {
	&__grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: var(--p-rhythm);
	}

	&__image {
		border-radius: 100%;
		width: 80%;
	}

	&__title {
		color: var(--c-turquiose);
		margin-top: 0.6em;
	}

	& span {
		display: block;
	}

	@include mixins.from-md {
		&__grid {
			grid-template-columns: repeat(4, 1fr);
			gap: var(--p-rhythm) var(--p-rhythm-sm);
		}

		&__title {
			margin-top: 1em;
		}
	}
}
