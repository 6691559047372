@use "mixins";

.page-fold {
	margin: var(--p-rhythm-lg) 0;
	grid-area: fold;
	display: grid;
	gap: var(--p-rhythm);

	&__back {
		display: flex;
		align-items: center;
		gap: 1rem;
		font-size: 12px;
		letter-spacing: 2px;
		text-transform: uppercase;
		margin-bottom: 14px;

		&-arrow {
			color: var(--t-accent-color);
			transform: rotate(180deg);
			width: 5px;
			height: auto;
			margin-bottom: 1px;

			svg {
				display: block;
			}
		}
	}

	&__tagline {
		font-size: var(--fs-big-title);
	}

	&__text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;

		& > .btn {
			margin-top: calc(var(--p-rhythm-sm) * 1.5);
		}
	}

	&__image {
		& img {
			width: 100%;
			height: auto;
		}
	}

	&--thin {
		gap: 0;
		margin: var(--p-rhythm-lg) 0 var(--p-rhythm) 0;
	}

	@include mixins.from-md {
		grid-template-columns: auto 430px;
		margin: var(--p-rhythm-lg) 0 calc(var(--p-rhythm-lg) * 1.2) 0;

		&__back {
			display: none;
		}

		&--thin {
			margin: var(--p-rhythm-lg) 0 var(--p-rhythm) 0;
		}
	}
}
