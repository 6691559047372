@use "mixins";

.support-value {
	& * {
		font-size: var(--fs-body);
	}

	&__image {
		margin: var(--p-rhythm) 0;
	}

	&__title {
		color: var(--c-turquiose);
	}

	& + & {
		margin-top: var(--p-rhythm-sm);
	}

	@include mixins.from-md {
		padding: 0 var(--p-rhythm-lg);
	}
}
