@use "mixins";
@use "constants";
@use "global/bases";

.l-form-builder-page {
	gap: var(--p-rhythm);

	&__submitted {
		grid-column: span 2;
		font-size: var(--fs-body);
	}

	&__intro {
		grid-column: span 2;
	}

	&__info {
		// mini richtext... but not really bc spacing
		& * {
			font-weight: 400;
		}

		& > h3 {
			color: var(--t-accent-color);
			font-family: constants.$body-font;
		}

		& > p {
			& + h3 {
				margin-top: 1em;
			}
		}

		a {
			width: auto;
			@include bases.base-link(
				var(--c-turquiose),
				var(--c-turquiose),
				var(--c-turquiose)
			);
		}
	}

	@include mixins.to-md {
		display: flex;
		flex-direction: column-reverse;
	}

	@include mixins.from-md {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
}
