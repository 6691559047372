@use "constants";
@use "mixins";

@mixin heading-font() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: constants.$heading-font;
	letter-spacing: 0.05em;
}

@mixin body-font() {
	font-family: constants.$body-font;
}

@mixin base-heading() {
	@include heading-font;
	font-weight: 700;
	line-height: 1;
	margin: 0;
}

@mixin base-link(
	$hoverColour: currentColor,
	$lineColour: currentColor,
	$initialLine: transparent
) {
	transition: all 150ms ease-in;
	text-decoration: underline $initialLine 2px;
	text-underline-offset: 0.4rem;
	color: inherit;
	display: inline-block;
	position: relative;

	&:hover,
	&:focus,
	&:active {
		color: $hoverColour;
		text-decoration-color: $lineColour;
	}
}

@mixin title {
	@include base-heading;
	font-size: var(--fs-title);
}

@mixin button {
	line-height: 1;
	border: 1px solid var(--c-turquiose);
	display: inline-block;
	transition: border-color 250ms ease-in-out;
	width: auto;
	padding: 0.5em 2.5em;
	cursor: pointer;
	font-weight: normal;

	&:hover,
	&:focus,
	&:active {
		border-color: var(--c-orange);
	}
}
