@use "mixins";

.l-page {
	$c: &;
	max-width: var(--page-width);
	margin: 0 auto;

	@include mixins.from-md {
		display: grid;
		grid-template-areas:
			"fold  fold    fold"
			"aside content ."
			"cta   cta     cta";
		grid-template-columns: 1fr var(--content-width) 1fr;
		column-gap: var(--p-rhythm);

		// Landing pages
		&--landing {
			grid-template-areas:
				"fold  fold    fold"
				"intro intro intro"
				"aside content ."
				"cta   cta     cta";
			grid-template-columns: 1fr var(--landing-width) 1fr;
			column-gap: 0;
		}

		&--listing {
			grid-template-columns: 1fr var(--listing-width) 1fr;
		}
	}

	&__title {
		grid-area: fold;
		font-size: var(--fs-big-title);
		padding: var(--p-rhythm-lg) 0 var(--p-rhythm) 0;
	}

	&__aside {
		grid-area: aside;

		@include mixins.to-md {
			display: none;
		}
	}
	&__content {
		grid-area: content;
	}

	&__cta {
		grid-area: cta;
		position: relative;
		margin-top: var(--p-rhythm-lg);

		&-text {
			text-align: center;
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			padding: 0 var(--p-rhythm);
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			& > h2 {
				font-size: var(--fs-title);
				margin-bottom: calc(var(--p-rhythm-sm) * 1.5);
			}
		}

		&-buttons {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap: var(--p-rhythm-sm);
		}

		@include mixins.from-md {
			&-text {
				padding: 0 var(--p-rhythm-lg);
			}
		}
	}

	// Staff listing
	&--wide {
		@include mixins.from-md {
			grid-template-areas:
				"fold  fold    fold"
				"aside content content"
				"cta   cta     cta";
		}
	}

	// Homepage
	&--fullwidth-content {
		max-width: none;
		display: block !important;

		& .l-page {
			&__cta {
				max-width: var(--page-width);
				margin-top: 0;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}
