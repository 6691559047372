@use "mixins";

.icon-block {
	display: grid;

	&__title {
		color: var(--c-turquiose);
	}

	@include mixins.to-md {
		&__icon {
			margin-bottom: var(--p-rhythm-sm);
		}
	}

	@include mixins.from-md {
		grid-template-rows: 100px auto auto;

		&--minimal {
			grid-template-rows: 80px auto auto;
		}
	}
}
