.browser-detect {
	padding: var(--p-rhythm-sm);
	border-bottom: 1px solid white;
	background-color: var(--c-blue-dark);

	&--button {
		margin-top: 1em;
		border: 1px solid var(--c-turquiose);
		display: inline-block;
		width: auto;
		padding: 0.5em 1.5em;
	}
}
