@use "mixins";
@use "global/bases";

.cards {
	$c: &;

	&__title {
		@include bases.title;
		margin-bottom: var(--p-rhythm-sm);
	}

	&__grid {
		columns: 2;
		display: grid;
		grid-auto-flow: dense;

		#{$c}__card {
			&:not(:last-child) {
				margin-bottom: var(--p-rhythm-lg);
			}

			& > span {
				display: block;
				line-height: 1;
				margin-top: calc(var(--p-rhythm-sm) / 2);
				color: var(--c-turquiose);
				margin-bottom: 0.3em;
			}
		}

		@include mixins.from-md {
			grid-template-columns: 1fr 1fr;
			column-gap: var(--p-rhythm);

			#{$c}__card {
				margin-bottom: 0;
			}
		}
	}
}
