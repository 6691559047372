@use "mixins";

.page-listing {
	display: grid;
	row-gap: var(--p-rhythm-lg);

	&--home {
		margin-top: var(--p-rhythm);
	}
	@include mixins.from-md {
		row-gap: var(--p-rhythm);
	}
}

.page-listing-item {
	&__supertitle {
		font-size: 15px;
		letter-spacing: 2px;
		text-transform: uppercase;
		margin-bottom: 0.6em;
	}
	&__supertitle,
	&__title {
		color: var(--c-turquiose);
	}

	&__title,
	&__description {
		font-size: var(--fs-body);
	}
	&__description {
		line-height: 1.2;
		margin-top: 0.5em;
	}
	&__text {
		grid-area: text;
		display: flex;
		flex-direction: column;
		justify-content: center;

		& > a {
			margin-top: calc((var(--p-rhythm-sm) * 1.5) - 15px); // LM
		}
	}

	&__image {
		grid-area: image;
	}
	@include mixins.to-md {
		&__text {
			margin-top: var(--p-rhythm-sm);
		}
	}
	@include mixins.from-md {
		display: grid;
		grid-template-columns: 270px auto;
		grid-template-areas: "image text";
		column-gap: 50px;

		&__large {
			grid-template-columns: 450px auto;
			grid-template-rows: 510px;

			.page-listing-item {
				&__title {
					color: var(--t-text-color);
					font-size: var(--fs-title);
					margin-bottom: 1em;
				}
			}
		}
	}
}
