.sidebar-nav {
	color: var(--c-turquiose);
	position: sticky;
	top: var(--p-rhythm);
	bottom: var(--p-rhythm);

	&__link {
		line-height: 1.2;
		text-decoration: underline 2px transparent;
		transition: all 150ms ease-in-out;
		text-underline-offset: 6px;

		& + & {
			margin-top: 0.9em;
		}

		&--child {
			padding-left: 1em;
			position: relative;

			&::before {
				content: "–";
				left: 0;
				position: absolute;
			}

			margin-top: 0.5em !important;
		}

		&:hover {
			color: var(--c-orange);
		}

		&:focus,
		&--current {
			text-decoration-color: currentColor;
		}
	}
}
