@use "mixins";

.solutions {
	columns: 2;
	display: grid;
	grid-auto-flow: dense;

	& * {
		font-size: var(--fs-body);
	}
	&__feature {
		grid-row: span 2;
		align-self: flex-end;
		margin-top: var(--p-rhythm-lg);

		& > span {
			display: block;
			line-height: 1;
			margin-top: calc(var(--p-rhythm-sm) / 2);
			color: var(--c-turquiose);
			margin-bottom: 0.3em;
		}

		& > p {
			line-height: 1.2;
		}
	}

	&__intro {
		& > h3 {
			font-size: var(--fs-title);
			margin-bottom: 1em;
		}

		& > a {
			margin-top: var(--p-rhythm-sm);
		}
	}

	@include mixins.from-md {
		grid-template-columns: 1fr 1fr;
		column-gap: var(--p-rhythm);
	}
}
